import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { DATA_USER } from "../localService/localService";

const headers: AxiosRequestConfig["headers"] = {
  "Content-Type": "application/json", // Example header
  // Authorization: "Bearer " + token,
};
// console.log(process.env.REACT_APP_ENV);
// console.log(process.env.REACT_APP_ENDPOINT);
// console.log(process.env.REACT_APP_ENDPOINTFE);
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  // baseURL: "https://qtlytps.mvctech.vn",
  headers,
});
export const axiosOCRInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINTFE,
  headers: {
    "Content-Type": "application/json",
  },
});
export const setAuthorization = (token: string) => {
  axiosInstance.defaults.headers.Authorization = "Bearer " + token;
};
axiosInstance.interceptors.response.use(
  function (response) {
    // console.log(response);
    if (response.data?.meta?.status) {
      return response.data.data;
    } else {
      return Promise.reject(response.data?.meta?.message);
    }
  },
  function (error: AxiosError) {
    console.log(error);
    if (error.code === "ERR_NETWORK") {
      window.location.assign("/login");
      localStorage.removeItem(DATA_USER);
      localStorage.removeItem("CHECK_STATUS");
    }
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 400:
        message = "Access token invalid";
        break;
      default:
        message = error.message || error;
    }

    return Promise.reject(message);
  }
);
