import "../../assets/css/fontText.css";
import "../../assets/css/HomePage.css";
import Navbar from "../../components/NavbarMenu/Navbar";
import Brand from "./Brand/Brand";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import { useDispatch } from "react-redux";
import { getAllBannerHome } from "../../redux/slices/HomeSlice";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../redux/stores/store";
import {
  BACK_GLOBAL,
  locationBefore,
  userLocal,
} from "../../services/localService/localService";
import { useLocation, useNavigate } from "react-router-dom";
import ChangeGiftPhysical from "./Campaign/ChangeGiftPhysical";
import ControlBar from "./ControlBar/ControlBar";
import Promotion from "./Promotion/Promotion";
import Campaign from "./Campaign/Campaign";
import ItemTabHome from "./ItemTabHome/ItemTabHome";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import TCICON from "../../assets/image/Prize/tcicon.png";
import { homeServices } from "../../services/apiService/homeService";
import { setAuthorization } from "../../services/apiService/configURL";
import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import { HomePageModel } from "../../models/HomePage";
import { useQuery } from "react-query";
import TabNewsEvent from "./ItemTabHome/TabNewsEvent";

export interface AxiosResponse<T = any, D = any> {
  data: HomePageModel;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
}

export default function HomePage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();
  const { pathname } = useLocation();
  let { token, name } = userLocal.get();
  const [listNews, setListNews] = useState<any>();
  localStorage.setItem(BACK_GLOBAL, "");
  document.body.style.backgroundColor = "white";
  setAuthorization(token);
  useEffect(() => {
    localStorage.setItem("LOCATION_STATUS_GAME", "TRUE");
    localStorage.removeItem("REVIEW_IMG");
    localStorage.removeItem("GAME_DATA_LOCAL");
    localStorage.removeItem("LUOT_QUAY");
    localStorage.removeItem("SALE_ID");
    locationBefore.set(pathname);
    dispatch(getAllBannerHome());
    homeServices
      .GetNews()
      .then((res: any) => {
        setListNews(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [current, setCurrent] = useState<string>("1");
  const { data, isLoading } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const handleClick = (e: any, status_gifts: string) => {
    window.scrollTo(0, 0);
    setCurrent(e.target.id);
  };
  const handleLogin = () => {
    navigation("/login");
  };
  const handleTC = () => {
    navigation("/tc");
  };
  return (
    <div className="">
      <div className="relative top-0 w-full">
        <div className="relative">
          <HeaderMenu dataInfor={data} isLoading={isLoading} />
          <div className="absolute top-5 px-5 w-full flex justify-between text-[14px]">
            {token ? (
              <div>
                <div className="bg-white opacity-40 px-3 py-1 rounded-3xl font-light-so">
                  Xin chào{" "}
                  <span className="font-bold-so uppercase">{name}</span>
                </div>
              </div>
            ) : (
              <div
                className="grid grid-cols-2 font-medium-so text-center bg-[#B5E0EE] p-[1px] rounded-3xl"
                onClick={handleLogin}
              >
                <div className="col-span-1 py-1 text-white w-24 bg-[#4E9ABB] rounded-3xl">
                  Đăng nhập
                </div>
                <div className="col-span-1 w-20 flex items-center justify-center">
                  Đăng ký
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bg-[#B5E0EE] mx-5 px-3 py-1 rounded-[20px] shadow-item border-point grid grid-cols-2">
          <div className="col-span-1 py-1">
            <div className="text-[12px] font-light-so text-[#242E49]">
              Số điểm bạn đang có
            </div>
            <div>
              <div className="flex">
                <div className="col-span-2 flex items-center px-1">
                  <img
                    src={ICON_COIN}
                    loading="lazy"
                    width={19}
                    height={19}
                    alt="Image"
                  />
                </div>
                <div className="col-span-10">
                  <div className="text-[#005CB4] flex items-center">
                    <div className="text-[20px] font-semibold-so">
                      {data ? data?.points : 0}
                    </div>
                    <div className="text-[11px] font-regular-so ml-1 mt-1 text-[#3D4966]">
                      Pepcoin
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 px-1 py-2 font-medium-so">
            <div
              className="bg-white border-tc h-full rounded-3xl flex justify-around items-center text-[#4E9ABB] text-[12px] shadow-item"
              onClick={handleTC}
            >
              Thể lệ chương trình
              <div>
                <img src={TCICON} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <TabNewsEvent
            current={current}
            handleClick={(e, status) => handleClick(e, status)}
          />
        </div>
        <div className="relative top-0">
          {current === "1" && (
            <div>
              <ControlBar numberRotation={data} />
              <Promotion lstGame={data} />
              <Brand lstBrands={data} />
              <Campaign lstExchangeGift={data} />
              <ChangeGiftPhysical lstExchangeGift={data} />
            </div>
          )}
          {current === "2" && (
            <div className="px-5">
              <ItemTabHome itemNews={listNews.brand_news} />
            </div>
          )}
          {current === "3" && (
            <div className="px-5">
              <ItemTabHome itemNews={listNews.event_news} />
            </div>
          )}
          {current === "1" && <div className="h-20"></div>}
          {current === "2" && <div className="h-40"></div>}
          {current === "3" && <div className="h-40"></div>}
        </div>
      </div>

      <Navbar />

      <ContactPhoneZalo />
    </div>
  );
}
