import ICON_DOTS from "../../assets/image/GuideTakePhoto/icon-dot.png";
import ICON_DOTS_COLOR from "../../assets/image/GuideTakePhoto/icon-dots-color.png";
import BACK from "../../assets/image/Header/back-header.png";
import B1_TAKEAPHOTO from "../../assets/image/GuideTakePhoto/photo1.png";
import B2_TAKEAPHOTO from "../../assets/image/GuideTakePhoto/photo2.png";
import B3_TAKEAPHOTO from "../../assets/image/GuideTakePhoto/photo3.png";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import CarouselTakeAPhotoItem from "./CarouselTakeAPhotoItem";
import { useEffect, useState } from "react";
import PopupLogin from "../../popup/PopupLogin";
import CLOSE from "../../assets/image/Home/cancel-popup.png";
import { GameServices } from "../../services/apiService/GameService";

const images = [
  {
    id: 1,
    url: B1_TAKEAPHOTO,
    title: `<div>Chụp hóa đơn <b>gốc</b> và chụp <b>toàn bộ</b> hóa đơn: đủ sáng rõ nét</div>`,
  },
  {
    id: 2,
    url: B2_TAKEAPHOTO,
    title: `<div>Nếu hóa đơn dài, cần <b>gấp</b> lại để hiển thị rõ: <b>tên sản phẩm và số lượng, tên siêu thị, số hóa đơn, ngày mua</b></div>`,
  },
  {
    id: 3,
    url: B3_TAKEAPHOTO,
    title: `<div>Nền phía sau <b>trơn - đơn giản </b></div>`,
  },
];

export default function CarouselPhoto() {
  const navigation = useNavigate();
  const handleBack = () => {
    document.body.style.overflow = "unset";
    navigation("/");
  };
  const { id } = useParams();

  useEffect(() => {
    document.body.style.backgroundColor = "#ffffff";

    window.scrollTo(0, 0);
    GameServices.validateTime()
      .then((res: any) => {
        console.log(res);
        // navigation(`/guide-take-photo/${id}`);
      })
      .catch((err) => {
        setErr(err);
        setIsPopup(true);
        console.log(err);
      });
  }, []);
  const [err, setErr] = useState("");
  const [isPopup, setIsPopup] = useState(false);
  const CustomDot = ({ onMove, index, onClick, active }: any) => {
    return (
      <div>
        <li
          className={`${
            active ? "text-red-600" : "text-black"
          } relative -top-[430px] px-1`}
          onClick={() => onClick()}
        >
          <div>
            {active ? <img src={ICON_DOTS_COLOR} /> : <img src={ICON_DOTS} />}
          </div>
        </li>
      </div>
    );
  };
  const handleClose = () => {
    document.body.style.overflow = "unset";
    setIsPopup(false);
    navigation("/");
  };
  return (
    <div>
      {isPopup && (
        <PopupLogin isShow={isPopup}>
          <div>
            <div className="">
              <div className="text-[24px] text-[#2D4E68] font-semibold-so pt-5 uppercase">
                Thông báo
              </div>
            </div>
            <div className="pt-5 pb-10">{err}</div>
            <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
              <img src={CLOSE} onClick={handleClose} />
            </div>
          </div>
        </PopupLogin>
      )}
      <div>
        <div className="relative">
          <div className="absolute top-5 left-5 w-12 z-20" onClick={handleBack}>
            <img src={BACK} className="w-9" />
          </div>
          <Carousel
            showDots={true}
            additionalTransfrom={0}
            autoPlaySpeed={3000}
            autoPlay
            centerMode={false}
            className="pb-5"
            containerClass="container-with-dots"
            dotListClass="custom-dot-list-style"
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            customDot={<CustomDot />}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 50,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            arrows={false}
          >
            {images.map((item, index) => (
              <div key={index} className="relative">
                <CarouselTakeAPhotoItem item={item} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
