import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ListBanner } from "../../../models/HomePage";

BannerPromotion.propTypes = {
  item: PropTypes.object,
};
export default function BannerPromotion({
  id,
  img,
  type,
  campaign_id,
  name,
  campaign_time,
  campaign_type,
}: ListBanner) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (campaign_type === "special") {
      navigate(`/${campaign_type}/tc/${campaign_id}`);
    } else {
      navigate(`/prize-game/${campaign_id}`);
    }
  };

  return (
    <div
      className="relative rounded-2xl border-shadow-campaign mr-4 mt-3 max-w-[360px]"
      onClick={handleNavigate}
    >
      <div className="rounded-2xl">
        <img
          src={img}
          loading="lazy"
          // width={}
          className="rounded-2xl min-w-[340px]"
          alt="Image"
        />
      </div>
      <div className="px-3 text-[#2D4E68] textcampaign-reponsive line-clamp-3 relative min-h-[26px] mt-2 mb-1 font-semibold-so max-w-[340px]">
        {name}
      </div>
      <div className="relative bottom-0 text-[14px] left-auto flex justify-end">
        <div className="bg-[#76DBF4] relative right-[11px] pl-[6px] w-[165px] rounded-tl-3xl rounded-br-2xl mt-0">
          <div className="bg-[#5BC2DC] font-light-so text-center w-[170px] py-1 rounded-tl-3xl rounded-br-2xl text-white">
            {campaign_time}
          </div>
        </div>
      </div>
    </div>
  );
}
