import { Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import { userLocal } from "../../../services/localService/localService";
import { useEffect, useState } from "react";
import { GameServices } from "../../../services/apiService/GameService";
import { Campaign } from "../../../models/GameModel";
import HeaderBack from "../../../components/HeaderBack/HeaderBack";
import BACK_HEADER from "../../../assets/image/Header/back-header.png";
import PopupLogin from "../../../popup/PopupLogin";
import CLOSE from "../../../assets/image/Home/cancel-popup.png";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";

export default function SpecialTC() {
  let campaign_id = window.location.pathname.split("/")[3];
  const { id } = useParams();
  const [prizeGame, setPrizeGame] = useState<Campaign>();
  const navigation = useNavigate();
  let { token } = userLocal.get();
  const [isShowDisable, setIsShowDisable] = useState(false);
  const [isShowNotify, setIsShowNotify] = useState(false);
  const handleJoinGame = () => {
    if (!prizeGame?.disable) {
      //   navigation(`/guide-page-picture/${id}`);
      setIsShowNotify(true);
    } else {
      setIsShowDisable(true);
    }
  };
  const handleJoin = () => {
    setIsShowNotify(false);
    document.body.style.overflow = "unset";
    navigation(`/guide-page-picture/${id}`);
  };
  const getDAta = (campaign_id: string, token: any) => {
    GameServices.GameDetailsApi(campaign_id, token)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setPrizeGame(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDAta(campaign_id, token);
  }, []);
  const handleClickGift = (e: string) => {
    if (!prizeGame?.disable) {
      navigation(e);
    }
  };
  const handleClose = () => {
    setIsShowDisable(false);
    setIsShowNotify(false);
  };
  return (
    <Fragment>
      <div
        className="relative top-0"
        style={{ backgroundColor: prizeGame?.background_color }}
      >
        <div
          className="relative top-0 rounded-b-3xl"
          style={{ backgroundColor: prizeGame?.background_color }}
        >
          <div
            className="w-full h-20 relative"
            style={{ backgroundColor: prizeGame?.background_color }}
          ></div>
          <div className="relative -top-14">
            <img
              src={prizeGame?.banner_image_url}
              loading="lazy"
              className="rounded-3xl w-full"
            />
          </div>

          <div className="absolute top-5 px-5 w-full">
            <HeaderBack
              name=""
              navigate="/"
              imageBack={prizeGame?.button_back_image_url as string}
            />
          </div>
          <div className="relative">
            <div
              className="uppercase text-[20px] -mt-12 line-clamp-2 px-3 font-semibold-so pb-1 text-white"
              style={{ color: prizeGame?.campaign_text_color }}
            >
              {prizeGame?.campaign_name}
            </div>
          </div>
          <div className="flex justify-end mt-1">
            <div className="pl-[6px] rounded-tl-3xl rounded-br-2xl relative bottom-0 right-0 w-44">
              <div className="absolute right-0 z-0 w-full">
                <img src={prizeGame?.label_date_image_url} className="w-full" />
              </div>
              <div
                className="relative z-10 font-light-so text-white rounded-br-2xl py-1 text-center rounded-tl-3xl"
                style={{ color: prizeGame?.campaign_text_color }}
              >
                {prizeGame?.campaign_time}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pt-5 -mt-[1px]"
        style={{ backgroundColor: prizeGame?.background_color }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: prizeGame?.content_details,
          }}
          className="font-light-so px-6 pb-44 point-ul line-table text-[14px]"
        ></div>
        <div className="flex justify-center flex-col pb-10 fixed bottom-0 w-full px-6 ">
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-1">
              <button
                onClick={() =>
                  handleClickGift(`/special/list-receive/gift/${id}`)
                }
              >
                <img src={prizeGame?.button_gift_enable_image_url} />
              </button>
            </div>
            <div className="col-span-1">
              <button
                onClick={() =>
                  handleClickGift(`/special/list-receive/prize/${id}`)
                }
              >
                <img src={prizeGame?.button_prize_enable_image_url} />
              </button>
            </div>
          </div>
          <div className="mt-3">
            <button
              onClick={handleJoinGame}
              className="w-full flex justify-center"
            >
              <img src={prizeGame?.button_join_image_url} className="" />
            </button>
          </div>
        </div>
      </div>
      <PopupOverlay isShow={isShowNotify}>
        <div
          style={{
            background: `url(${prizeGame?.background_popup_image_url})`,
            width: "95%",
            // height: "200px",
            backgroundPosition: "center",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          className="px-10"
        >
          <div className="py-5 px-5 text-center relative font-regular-so">
            <div className="uppercase text-[#FB0301] mt-3 text-[28px] font-bold-so">
              Thông báo
            </div>
            <div className="text-left text-white">
              - Hình hóa đơn tham gia chương trình chỉ áp dụng trong vòng{" "}
              <span className="text-[#FB0301] font-semibold-so">
                03 ngày kể từ thời điểm xuất hóa đơn.
              </span>{" "}
              <br />- Mỗi khách hàng chỉ được đổi quà bằng Pepcoin{" "}
              <span className="text-[#FB0301] font-semibold-so">
                tối đa 3 lần/ngày.
              </span>{" "}
              <br />- Mỗi khách hàng chỉ được nhận được{" "}
              <span className="text-[#FB0301] font-semibold-so">
                tối đa 500 Pepcoin/tháng.
              </span>
            </div>
            <div className="mb-7 mt-5">
              <button
                className="px-14 py-4 rounded-2xl shadow-item text-white"
                onClick={handleJoin}
              >
                <img src={prizeGame?.button_agree_image_url} />
              </button>
            </div>
          </div>

          <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
            <img src={CLOSE} onClick={handleClose} />
          </div>
        </div>
      </PopupOverlay>
      <PopupLogin isShow={isShowDisable}>
        <div className="py-5">
          <div className="uppercase text-[#2D4E68] text-center text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="py-5">
            Chờ đón nhiều Chương trình Khuyến Mãi siêu hot đang sắp diễn ra!
          </div>
          <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
            <img src={CLOSE} onClick={handleClose} />
          </div>
        </div>
      </PopupLogin>
    </Fragment>
  );
}
