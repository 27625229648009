import Carousel from "react-multi-carousel";
import { Fragment } from "react/jsx-runtime";
import ICON_DOTS from "../../../assets/image/GuideTakePhoto/icon-dot.png";
import ICON_DOTS_COLOR from "../../../assets/image/GuideTakePhoto/icon-dots-color.png";
import LoadingButton from "../../../components/Loading/LoadingButton";
import SkelatonCampaignTop from "../../../components/Skelaton/SkelatonCampaignTop";
import { useNavigate } from "react-router-dom";

export default function HeaderMenu({
  dataInfor,
  isLoading,
}: {
  dataInfor: any;
  isLoading: boolean;
}) {
  const navigation = useNavigate();
  const CustomDot = ({ onMove, index, onClick, active }: any) => {
    return (
      <li
        className={`${active ? "text-red-600" : "text-black"} relative -top-7`}
        onClick={() => onClick()}
      >
        <div>
          {active ? <img src={ICON_DOTS_COLOR} /> : <img src={ICON_DOTS} />}
        </div>
      </li>
    );
  };
  const handleRedirectDetails = (campaign_id: number | string) => {
    if (campaign_id !== "") {
      navigation(`/prize-game/${campaign_id}`);
    } else {
      navigation(`/tc`);
    }
  };
  return (
    <Fragment>
      <div>
        {dataInfor ? (
          <Carousel
            showDots={true}
            additionalTransfrom={0}
            autoPlaySpeed={10000}
            autoPlay
            centerMode={false}
            className="pb-5"
            containerClass="container-with-dots"
            dotListClass="custom-dot-list-style"
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            customDot={<CustomDot />}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 50,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            arrows={false}
          >
            {dataInfor?.banners?.map((res: any, index: number) => (
              <div
                key={index}
                onClick={() =>
                  handleRedirectDetails(res?.campaign_id as string | number)
                }
              >
                <img src={res?.banner_url} className="rounded-b-3xl" />
              </div>
            ))}
          </Carousel>
        ) : (
          <SkelatonCampaignTop />
        )}
      </div>
    </Fragment>
  );
}
